import { useState } from "react";
import UiButton from "../components/UiBase/UiButton";
import useEmailHandling from "../utils/useEmailHandling";
import toast, { Toaster } from "react-hot-toast";

type Props = {};

function Form() {
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [name, setName] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const emailHandler = useEmailHandling();

  const handleError = (error: any) => {
    console.error(error);
  };

  const handleSubmit = () => {
    try {
      if (!emailRegex.test(email)) {
        toast.error("Invalid email address.");
        return;
      }

      if (!body.trim()) {
        toast.error("Message body cannot be empty.");
        return;
      }

      emailHandler
        .sendEmail(body, email, name)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Message sent successfully!");
          } else {
            alert("Message failed to send. Please try again later.");
          }
        })
        .then(() => {
          setEmail("");
          setBody("");
          setName("");
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white flex flex-col gap-5 p-8 mx-auto shadow-lg w-96 rounded-2xl lg:w-1/2 lg:p-12 lg:rounded-3xl lg:shadow-2xl"
    >
      <div>
        <label
          htmlFor="text"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Your name
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          placeholder="James"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring focus:ring-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        />
      </div>
      <div>
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Your email
        </label>
        <input
          type="email"
          value={email}
          placeholder="james131@gmail.com"
          onChange={(e) => setEmail(e.target.value)}
          required
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        />
      </div>
      <div>
        <label
          htmlFor="body"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          What would you like to talk about?
        </label>
        <textarea
          placeholder="I would like to know more about... and I have a few questions on..."
          value={body}
          onChange={(e) => setBody(e.target.value)}
          required
          style={{ resize: "none" }}
          className="bg-gray-50 border border-gray-300 h-32 text-gray-900 text-sm rounded-lg focus:ring focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        />
      </div>
      <UiButton
        handler={() => handleSubmit()}
        text="Send Message	"
        color="primary"
        textColor="white"
        grow={false}
      />
      <Toaster />
    </form>
  );
}

const Contact = (props: Props) => {
  return (
    <div className=" min-h-screen">
      <div className="container flex flex-col items-center justify-center gap-12 py-40 lg:flex-row">
        <div className="flex flex-col w-3/4 gap-8 py-16 sm:px-16 rounded-2xl lg:w-1/2">
          <div className="flex flex-col gap-2">
            <h2 className="md:text-3xl text-primary-500 text-4xl font-black md:text-[40px]">
              Let's Talk!
            </h2>
            <p className="md:text-3xl text-4xl md:text-[40px] block font-bold tracking-normal text-dark">
              We're Here to Help
            </p>
          </div>
          <div className="max-w-lg text-base sm:text-lg text-body-color">
            Whether you have questions, ideas, or you're ready to kickstart your
            digital journey, we're here for you. Drop us a message and let's
            start the conversation.
          </div>
        </div>
        <Form />
      </div>
    </div>
  );
};

export default Contact;
