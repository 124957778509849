const Services = () => {
  return (
    <section className=" py-24 lg:pb-[90px]">
      <div className="container mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="flex flex-col items-center max-w-2xl gap-2 mx-auto mb-16 text-center lg:mb-20">
              <span className="block mb-2 text-lg font-bold uppercase text-primary-500 hover:text-primary-600">
                Successful solutions for your business
              </span>
              <h2 className="mb-3 md:text-3xl text-4xl font-extrabold leading-[1.2] text-dark md:text-[40px]">
                Go From Concept To Launch
              </h2>
              <p className="max-w-xl text-base sm:text-lg text-body-color ">
                Our services are designed to help you achieve your business
                goals.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center -mx-4 gap-8">
          <div className="flex-1 flex flex-col justify-center gap-8 md:flex-row">
            <ServiceCard
              title="Custom Websites & Consulting"
              details="Crafting powerful, user-friendly websites tailored to your business needs. Also we offer consulting services to help you make the right decisions."
            />
            <ServiceCard
              title="E-commerce Platforms"
              details="Empower your online store with custom e-commerce platforms designed for a seamless shopping experience and increased sales."
            />
          </div>
          <div className="flex-1 flex flex-col justify-center gap-8 md:flex-row">
            <ServiceCard
              title="Mobile Apps"
              details="Extend your reach with custom mobile applications for iOS and Android, designed to engage your audience on the go."
            />
            <ServiceCard
              title="CRM Solutions"
              details="Designing comprehensive CRM applications for web and mobile to streamline customer relationship management."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

const ServiceCard = ({
  icon,
  title,
  details,
}: {
  icon?: JSX.Element;
  title: string;
  details: string;
}) => {
  return (
    <>
      <div className="hover:border-primary-500 max-w-md p-10 border border-slate-400 transition-all max-h-max rounded-3xl md:px-7 xl:px-10">
        <div className="flex flex-col gap-4 mb-4 text-center">
          {icon && (
            <div className="flex min-h-[54px] min-w-[54px] items-center justify-center rounded-xl bg-primary-500">
              {icon}
            </div>
          )}
          <h4 className="text-2xl font-semibold text-left text-dark">
            {title}
          </h4>
        </div>
        <p className="text-lg text-left text-body-color text-balance ">
          {details}
        </p>
      </div>
    </>
  );
};
