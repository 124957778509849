import React from "react";
import { Link } from "react-router-dom";

type Props = {
	route: string;
	text: string;
	color: string;
	textColor: string;
	small?: string;
};

const LinkButton = (props: Props) => {
	const { route, text, color, textColor } = props;


	const getButtonColor = () => {
		const classesMap: { [key: string]: string } = {
			white:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-dark capitalize rounded-md bg-white hover:bg-gray-100 lg:px-7 transition-all hover:scale-110",
			black:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-dark hover:bg-gray-800 lg:px-7 transition-all hover:scale-110",
			primary:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-primary-400 hover:bg-primary-500 lg:px-7 transition-all hover:scale-110",
			secondary:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-secondary-400 hover:bg-secondary-500 lg:px-7 transition-all hover:scale-110",
			third:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-third-400 hover:bg-third-500 lg:px-7 transition-all hover:scale-110",
			forth:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-forth-400 hover:bg-forth-500 lg:px-7 transition-all hover:scale-110",
		};

		let classes = classesMap[color];

		if (props.small === "true") {
			classes = classes.replace("px-6 py-3", "px-4 py-2");
		}

		return classes;
	};

	const getButtonTextColor = () => {
		const classesMap: { [key: string]: string } = {
			primary: "text-primary-400",
			secondary: "text-secondary-400",
			third: "text-third-400",
			forth: "text-forth-400",
			white: "text-white",
			black: "text-dark",
		};
		return classesMap[textColor];
	};

	return (
		<Link to={route} className={getButtonColor()}>
			<p className={getButtonTextColor()}>{text}</p>
		</Link>
		// <button onClick={onClick} className={getButtonColor()}>
		// 	<p className={getButtonTextColor()}>{text}</p>
		// </button>
	);
};

export default LinkButton;
