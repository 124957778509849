import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import Home from "./pages/Home";
import Layout from "./components/Layout/Layout";
import Homelogo from "./assets/logo_build_v2.png";
import Contact from "./pages/Contact";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      <Layout>
        <BrowserRouter>
          <div className="fixed top-0 z-10 flex justify-center w-full py-2 shadow-sm bg-dark">
            <div className="container flex items-center justify-between w-full font-light tracking-wide text-light ">
              <div>
                <Link to="/" className="[&.active]:text-primary-500">
                  <img src={Homelogo} alt="logo" className="h-12" />
                </Link>
              </div>
              <div className="flex gap-12">
                <Link
                  to="/signup"
                  className="[&.active]:underline underline-offset-4 [&.active]:text-primary-500 hover:text-primary-400 transition-all"
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </StrictMode>
  );
}
