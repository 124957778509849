import LinkButton from "../UiBase/LinkButton";

type Props = {};

const CallToAction = (props: Props) => {
  return (
    <>
      <section className="flex justify-center py-32 ">
        <div className="container mx-auto">
          <div className="transition-all hover:transform hover:scale-105 relative overflow-hidden rounded bg-gradient-to-tr from-primary-500  to-primary-400 py-12 px-8 md:p-[70px]">
            <div className="flex flex-col items-center gap-8 -mx-4">
              <div className="flex flex-col w-full gap-4 px-4">
                <span className="text-base font-bold text-center text-white uppercase">
                  Ready to get started?
                </span>
                <h2 className="mb-8 text-2xl font-light leading-tight text-center text-white lg:mb-0">
                  Discover what you can do with BuildSaas,{" "}
                  <span className="font-bold">
                    the best way to deliver your products.
                  </span>
                </h2>
              </div>
              <div className="transition-all hover:transform hover:scale-110 hover:shadow-xl lg:justify-end">
                <LinkButton
                  route="/signup"
                  text="Let's Grow Together"
                  color="white"
                  textColor="black"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;
