const Footer = () => {
  return (
    <footer className="mx-12 transition-all rounded-lg">
      <div className="w-full p-4 mx-auto md:py-8 text-center">
        <div className="sm:flex sm:items-center sm:justify-between">
          <ul className="flex flex-wrap items-center justify-center w-full gap-8 mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href={"/signup"} className="hover:text-primary-400">
                Get In Touch
              </a>
            </li>
            <li>
              <a href={"/signup"} className="hover:text-primary-400">
                Start Today
              </a>
            </li>
            <li>
              <a href={"/"} className="hover:text-primary-400">
                Go Top ↑
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 " />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024{" "}
          <a href="/" className="hover:text-primary-400">
            BuildSaas - Digital Agency
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
