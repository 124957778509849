import CallToAction from "../components/Home/CallToAction";
import Hero from "../components/Home/Hero";
import Partner from "../components/Home/Partner";
import Services from "../components/Home/Services";

type Props = {};

const Home = (props: Props) => {
  return (
    <div className="px-12 py-12">
      <Hero />
      <Services />
      <Partner />
      <CallToAction />
    </div>
  );
};

export default Home;
