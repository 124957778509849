import React from "react";

type Props = {
	handler: () => void;
	text: string;
	color: string;
	textColor: string;
	grow: boolean;
};

const UiButton = (props: Props) => {
	const { handler, text, color, textColor, grow = false } = props;

	const onClick = (e: React.MouseEvent) => {
		e.preventDefault();
		handler();
	};

	const getButtonColor = () => {
		let baseClass = "";

		let growClass = "hover:scale-110";

		const classesMap: { [key: string]: string } = {
			white:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-dark capitalize rounded-md bg-white hover:bg-gray-100 lg:px-7 transition-all",
			black:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-dark hover:bg-gray-800 lg:px-7 transition-all",
			primary:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-primary-400 hover:bg-primary-500 lg:px-7 transition-all",
			secondary:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-secondary-400 hover:bg-secondary-500 lg:px-7 transition-all",
			third:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-third-400 hover:bg-third-500 lg:px-7 transition-all",
			forth:
				"inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white capitalize rounded-md bg-forth-400 hover:bg-forth-500 lg:px-7 transition-all",
		};

		baseClass = classesMap[color];

		if (grow) {
			baseClass = baseClass.concat(" ").concat(growClass);
		}

		return baseClass;
	};

	const getButtonTextColor = () => {
		const classesMap: { [key: string]: string } = {
			primary: "text-primary-400",
			secondary: "text-secondary-400",
			third: "text-third-400",
			forth: "text-forth-400",
			white: "text-white",
			black: "text-dark",
		};
		return classesMap[textColor];
	};

	return (
		<button onClick={onClick} className={getButtonColor()}>
			<p className={getButtonTextColor()}>{text}</p>
		</button>
	);
};

export default UiButton;
