import emailjs from "@emailjs/browser";

const useEmailHandling = () => {
	const sendEmail = async (
		message: string,
		from: string,
		from_name: string = "Guest"
	) => {
		const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID || "";
		const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "";
		const receiverName = process.env.REACT_APP_EMAILJS_EMAIL_NAME || "";

		let mailResponse = await emailjs.send(
			serviceId,
			templateId,
			{
				to_name: receiverName,
				message: message,
				from_name: from_name.trim() || "Guest",
				reply_to: from,
			},
			{
				publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
			}
		);

		return mailResponse;
	};

	return { sendEmail };
};

export default useEmailHandling;
