import photo from "../../assets/UI-UX team-rafiki.png";
import LinkButton from "../UiBase/LinkButton";

type Props = {};

const Partner = (props: Props) => {
  return (
    <section className="container content-center py-16">
      <div className="mx-auto max-w-4xl grid items-center justify-center lg:grid-cols-2 md:grid-cols-1 ">
        <div className="col-span-1 flex flex-col gap-8">
          <h2 className="text-4xl font-extrabold text-gray-900 capitalize ">
            Why Choose BuildSaas?
          </h2>
          <p className="font-normal text-gray-600 md:text-lg dark:text-gray-400">
            Confident in our capabilities, in BuildSaas we make the user
            experience better and our clients happier. We deliver the best
            results that help you achieve your objectives.
          </p>
          <LinkButton
            route="/signup"
            text="Schedule Discovery Call"
            color="primary"
            textColor="black"
          />
        </div>
        <img className="w-full" src={photo} alt="" />
      </div>
    </section>
  );
};

export default Partner;
