import LinkButton from "../UiBase/LinkButton";

const Hero = () => {
  return (
    <>
      <div className=" relative flex items-center justify-center py-32">
        <div className="container flex items-center justify-center">
          <div className="flex flex-col items-center justify-center w-full px-4 ">
            <h1 className="md:text-4xl md:tracking-wide capitalize text-center mb-5 text-4xl font-black !leading-[1.208] text-dark sm:text-[42px] lg:text-[40px] xl:text-5xl">
              Launch your ideas to the world
            </h1>
            <p className="text-center mb-8 max-w-[660px] text-lg text-dark">
              We build the solution your business needs.
            </p>
            <div className="shadow-md">
              <LinkButton
                route="/signup"
                text="Let's Talk"
                color="primary"
                textColor="black"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
